// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clubs-and-charities-js": () => import("./../src/pages/clubs-and-charities.js" /* webpackChunkName: "component---src-pages-clubs-and-charities-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-js": () => import("./../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-bubble-payments-subscriptions-js": () => import("./../src/pages/integrations/bubble-payments-subscriptions.js" /* webpackChunkName: "component---src-pages-integrations-bubble-payments-subscriptions-js" */),
  "component---src-pages-integrations-glide-apps-payments-subscriptions-js": () => import("./../src/pages/integrations/glide-apps-payments-subscriptions.js" /* webpackChunkName: "component---src-pages-integrations-glide-apps-payments-subscriptions-js" */),
  "component---src-pages-integrations-js": () => import("./../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-integrations-qwilr-js": () => import("./../src/pages/integrations/qwilr.js" /* webpackChunkName: "component---src-pages-integrations-qwilr-js" */),
  "component---src-pages-integrations-webstarts-payments-subscriptions-js": () => import("./../src/pages/integrations/webstarts-payments-subscriptions.js" /* webpackChunkName: "component---src-pages-integrations-webstarts-payments-subscriptions-js" */),
  "component---src-pages-integrations-wix-js": () => import("./../src/pages/integrations/wix.js" /* webpackChunkName: "component---src-pages-integrations-wix-js" */),
  "component---src-pages-integrations-zoom-js": () => import("./../src/pages/integrations/zoom.js" /* webpackChunkName: "component---src-pages-integrations-zoom-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-services-businesses-js": () => import("./../src/pages/services-businesses.js" /* webpackChunkName: "component---src-pages-services-businesses-js" */),
  "component---src-pages-stripe-js": () => import("./../src/pages/stripe.js" /* webpackChunkName: "component---src-pages-stripe-js" */),
  "component---src-templates-blog-list-js": () => import("./../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

