module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-8135554-10","respectDNT":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Payhere","short_name":"Payhere","start_url":"/","background_color":"#ffffff","theme_color":"#004DC9","display":"minimal-ui","icon":"src/images/icon-512.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"748f3a1b8eaee09e27ddfaed2f375268"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
