/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React, { useEffect, useState } from "react"
import { AuthProvider } from "./src/contexts/auth"
import { getRequest } from "./src/utils/api"

const AuthWrapper = ({ children }) => {
  const [signedIn, setSignedIn] = useState(false)

  useEffect(() => {
    getRequest("https://app.payhere.co/api/auth/check")
      .then(res => res.json())
      .then(json => setSignedIn(json.signed_in))
      .catch(err => console.error("Failed to check auth state", err))
  }, [])

  return <AuthProvider value={{ signedIn }}>{children}</AuthProvider>
}

export const wrapRootElement = ({ element }) => {
  return <AuthWrapper>{element}</AuthWrapper>
}
