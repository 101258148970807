import qs from "qs"

const makeRequest = async (requestType, url, params) => {
  return await fetch(url, {
    method: requestType,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    credentials: "same-origin",
    body: JSON.stringify(params),
  })
}

export const uploadRequest = async (requestType, url, formData) => {
  return await fetch(url, {
    method: requestType,
    credentials: "same-origin",
    body: formData,
  })
}

export const getRequest = async (url, params) => {
  const queryString = qs.stringify(params)
  return await fetch(`${url}?${queryString}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  })
}

export const postForm = async (url, params) => {
  console.log("posting form", url, params)
  return await fetch(url, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    credentials: "same-origin",
    body: JSON.stringify(params),
  })
}

export const postRequest = async (url, params) =>
  await makeRequest("post", url, params)
export const putRequest = async (url, params) =>
  await makeRequest("put", url, params)
export const patchRequest = async (url, params) =>
  await makeRequest("patch", url, params)
export const deleteRequest = async (url, params) =>
  await makeRequest("delete", url, params)
